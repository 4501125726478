import React, { useEffect, useState } from 'react';
import {
   Divider,
   Descriptions,
   Tag,
   Row,
   Col,
   Button,
   Space,
   Tabs,
   Statistic,
   Card,
   Avatar
} from 'antd';
import CanDo from '@shared/lib/CanDo';
import { ReloadOutlined, EditOutlined, StarFilled } from '@ant-design/icons';
import ModalDriverForm from '@features/form-driver/ui/ModalDriverForm';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Title, StatusTag, DateWithAgo } from '@shared/ui';
import stringToColor from '@shared/utils/stringToColor';
import { GetDriver } from '@features/form-driver/model/services/GetDriver';
import RatingListOfEntity from '@widgets/RatingListOfEntity';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';
import { useNavigate } from 'react-router-dom';
// import { FinishedRouteCount } from './DriverComponents';
import ListOfPushNotifications from '@features/list-pushes/ui/ListOfPushNotifications';
// import ListOfRoutesOfDriver from '@features/list-route/ui/ListOfRoutesOfDriver';
import ListLeadOfDriver from '@features/list-lead/ui/ListLeadOfDriver';
import config from '@shared/config';
import ModalButtonBalanceCreate from '@features/list-balance-history/ui/ModalButtonBalanceCreate';
import ListOfBalance from '@features/list-balance-history/ui/ListOfBalance';
import EntityVerificationComponent from '@features/verification/ui/EntityVerificationComponent';

import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import Notes from '@features/notes/ui/index';
import { LeadHistoryCount } from './DriverComponents';

import { LeadCount, BalanceCount } from './DriverComponents';

import { Spin } from '@shared/ui';

const DriverDescription = ({ id }) => {
   const [data, setData] = useState({});
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const [modalShow, setModalShow] = useState(null);

   const autoDescriptionLabelsAndValues = [
      {
         label: 'Бренд авто',
         key: 'carBrand',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'Номер авто',
         key: 'carNumber',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'СТС авто',
         key: 'carSTS',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'Грузоподъемность',
         key: 'carCapacity',
         render: (_) => `${_} кг` || 'Не задано'
      }
   ];

   const driverDescriptionLabelsAndValues = [
      {
         label: 'ФИО',
         key: 'firstName',
         render: (_, record) =>
            `${record.lastName} ${record.firstName} ${record?.surName || ''}`
      },
      {
         label: 'Город',
         key: 'city',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'Телефон',
         key: 'mobileNumber',
         render: (_) => _
      },
      {
         label: 'Почта',
         key: 'email',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'Рейтинг',
         key: 'rating',
         render: (_) => (
            <Tag bordered={false}>
               <StarFilled style={{ color: '#ffca00' }} /> {_ ? _ / 10 : 0}
            </Tag>
         )
      },
      // {
      //    label: 'Категории',
      //    key: 'entityCategories',
      //    render: (_) => (
      //       <Space size="small" wrap>
      //          {_ &&
      //             _.map((item) => (
      //                <React.Fragment
      //                   key={`category-${item.id}-${item.materialCategory.name}`}>
      //                   {item && (
      //                      <Tag color={stringToColor(item.materialCategory.name)}>
      //                         {item.materialCategory.name}
      //                      </Tag>
      //                   )}
      //                </React.Fragment>
      //             ))}
      //       </Space>
      //    )
      // },
      {
         label: 'Дата регистрации',
         key: 'createdAt',
         render: (_) => `${timestampToNormalDDMMYY(_)} в ${timestampToNormalTime(_)}`
      },
      {
         label: 'Округи',
         key: 'districts',
         render: (_) => (_?.length > 0 ? _?.join(', ') : 'Не задано')
      },

      {
         label: 'Последний вход',
         key: 'lastSeenAt',
         render: (_) => <DateWithAgo date={_} showDate={false} />
      },

      {
         label: 'Есть паспорт РФ',
         key: 'havePassport',
         render: (_) => (_ === true ? 'Да' : 'Нет')
      }

      // {
      //    label: 'Есть документ удостоверяющий личность',
      //    key: 'haveDocument',
      //    render: (_) => (_ === true ? 'Да' : 'Нет')
      // }
   ];

   const fetchData = () => {
      setLoading(true);
      GetDriver(id).then((res) => {
         setData({ ...res });
         setLoading(false);
      });
   };

   useEffect(() => {
      fetchData();
   }, [id]);

   const closeModal = () => {
      fetchData();
      setModalShow(null);
   };

   const update = () => {
      setLoading(true);
      setTimeout(() => {
         fetchData();
      }, 500);
   };

   if (loading && !data?.id) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '300px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   return (
      <>
         <ModalDriverForm selectedDriver={modalShow} closeModal={closeModal} />
         <div style={{ margin: '25px 0' }}>
            <Space size="large" align="center" wrap>
               <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate(-1)}></Button>
               <Title
                  style={{
                     padding: 0,
                     margin: 0
                  }}>{`Водитель №${id}`}</Title>

               <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                  {data.avatarUrl && <Avatar src={data.avatarUrl} size={48} />}
                  <NewEntityTag date={data?.createdAt} isBig={true} />{' '}
                  <StatusTag status={data?.status} type="driver" />
               </div>
            </Space>
         </div>

         <Row gutter={24}>
            <Col span={24}>
               <Row gutter={24}>
                  <Space size="middle" style={{ marginLeft: '10px' }}>
                     <Button
                        onClick={() => update()}
                        icon={<ReloadOutlined />}
                        loading={loading}>
                        Обновить
                     </Button>
                     <CanDo permission="can_edit_drivers">
                        <Button
                           onClick={() => setModalShow(data)}
                           type="primary"
                           icon={<EditOutlined />}>
                           Редактировать
                        </Button>
                     </CanDo>

                     {/* 
                     <CanDo permission="can_edit_sellers">
                        {data.status === 'blocked' && (
                           <DeleteEntityButton
                              id={data.id}
                              buttonText="Удалить"
                              callbackFetch={deleteLeadById}
                              update={() => navigate('/sellers')}
                              permission="can_delete_sellers"
                           />
                        )}
                     </CanDo> */}
                  </Space>
               </Row>
            </Col>

            <Col span={24}>
               <Row gutter={24}>
                  <Col span={6}>
                     <Divider orientation="left">Информация</Divider>

                     <Descriptions size="small">
                        {driverDescriptionLabelsAndValues.map((e) => (
                           <Descriptions.Item
                              key={`${data.id}-${e.label}`}
                              label={e.label}
                              span={3}>
                              {e.render(data[e.key], data)}
                           </Descriptions.Item>
                        ))}

                        <Descriptions.Item
                           key={`meow-driver ${id}`}
                           label={`Завершенных заявок`}
                           span={3}>
                           <LeadCount driverId={id} />
                        </Descriptions.Item>

                        <Descriptions.Item
                           key={`meow-driver ${id}`}
                           label={`Взятых заявок`}
                           span={3}>
                           <LeadHistoryCount driverId={id} status={'inwork'} />
                        </Descriptions.Item>

                        <Descriptions.Item
                           key={`meow-driver ${id}`}
                           label={`Отмененных заявок`}
                           span={3}>
                           <LeadHistoryCount driverId={id} status="refused" />
                        </Descriptions.Item>

                        {/* <Descriptions.Item
                           key={`meow-driver ${id}`}
                           label={`Количество вывезенных маршрутов`}
                           span={3}>
                           <FinishedRouteCount driverId={id} />
                        </Descriptions.Item> */}
                     </Descriptions>

                     {/* <SellerLeads id={id} /> */}
                     <Divider orientation="left">Оценки водителя</Divider>

                     <RatingListOfEntity
                        settings={{ toEntityType: 'driver', toEntityId: id }}
                     />
                  </Col>{' '}
                  <Col span={5}>
                     <Divider orientation="left">Автомобиль</Divider>

                     <Descriptions size="small">
                        {autoDescriptionLabelsAndValues.map((e) => (
                           <Descriptions.Item
                              key={`${data.id}-${e.label}`}
                              label={e.label}
                              span={3}>
                              {e.render(data[e.key], data)}
                           </Descriptions.Item>
                        ))}
                     </Descriptions>

                     <Divider orientation="left">Комментарий</Divider>
                     {data?.notes || 'Нет комментариев'}

                     <Divider orientation="left">Push уведомления</Divider>

                     <ListOfPushNotifications entityId={id} entityType={'driver'} />
                  </Col>{' '}
                  <Col span={7}>
                     <Divider orientation="left">Верификации</Divider>

                     <EntityVerificationComponent
                        verified={data.verified}
                        entityType={'driver'}
                        entityId={id}
                        onChangeEntity={fetchData}
                        mobileNumber={data.mobileNumber}
                     />

                     {config.paymentMode && (
                        <ListOfBalance
                           driverId={id}
                           mobileNumber={data.mobileNumber}
                        />
                     )}

                     <ListLeadOfDriver driverId={id} />
                  </Col>
                  <Col span={6}>
                     <Notes entityId={id} entityType="driver" />
                  </Col>
                  {/* <Col span={10}>
                     <Tabs
                        tabPosition={'top'}
                        defaultActiveKey="1"
                        type="card"
                        items={[
                           {
                              label: 'История',
                              key: '1',
                              children: 'В разработке'
                           },
                           {
                              label: 'Связи продавца',
                              key: '2',
                              children: 'В разработке'
                           }
                        ]}
                     />
                  </Col> */}
               </Row>
            </Col>
         </Row>
      </>
   );
};

export default DriverDescription;
