import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, List, Tooltip, Badge, Typography } from 'antd';
import { GetAcceptanceList } from '../model/GetAcceptanceList';
import { Button } from '@shared/ui';
import { Divider } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import ModalButtonAcceptanceCreate from './ModalButtonAcceptanceCreate';
import { statusesOfAcceptances } from '@shared/const/statuses';
import stringToColor from '@shared/utils/stringToColor';
import ModalAcceptanceForm from './ModalAcceptanceForm';
import { statusesOfDrivers } from '@shared/const/badgeStatuses';
import { VerticalSpace } from '@shared/ui';
import {
   getColumnSearchProps,
   onSearchFilterTable,
   onTableChange,
   getStatusColumnFilterProps
} from '@shared/ui/Table';
import { deleteAcceptanceById } from '@shared/api/all/acceptance';
import DeleteEntityButton from '@widgets/Button/DeleteEntityButton';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';

const TableAcceptances = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedAcceptance, setSelectedAcceptance] = useState(null);

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: {
         sort: 'id',
         order: 'desc'
      }
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      fetchData();
   }, []);

   const columns = [
      {
         title: '№',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         filtered: !!filters?.['id'],
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         })
      }
   ];

   const getCategoryNames = (arrOfEntityCategories = []) => {
      return arrOfEntityCategories.map((item) => item?.materialCategory?.name);
   };

   const actions = [
      {
         title: 'Название',
         dataIndex: 'title',
         key: 'title',
         sorter: true,
         filtered: !!filters?.['title'],
         ...getColumnSearchProps({
            dataIndex: 'title',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'text'
         })
      },
      {
         title: 'Имя',
         dataIndex: 'firstName',
         key: 'firstName',
         sorter: true,
         filtered: !!filters?.['firstName'],
         ...getColumnSearchProps({
            dataIndex: 'firstName',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         })
      },
      {
         title: 'Фамилия',
         dataIndex: 'lastName',
         key: 'lastName',
         sorter: true,
         filtered: !!filters?.['lastName'],
         ...getColumnSearchProps({
            dataIndex: 'lastName',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         })
      },
      {
         title: 'Телефон',
         dataIndex: 'mobileNumber',
         key: 'mobileNumber',
         filtered: !!filters?.['mobileNumber'],
         ...getColumnSearchProps({
            dataIndex: 'mobileNumber',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         })
      },
      {
         title: 'Адрес',
         dataIndex: 'address',
         key: 'address',
         filtered: !!filters?.['address'],
         ...getColumnSearchProps({
            dataIndex: 'address',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'text'
         }),
         render: (_) => <>{_?.address}</>
      },
      {
         title: 'Категория',
         dataIndex: 'entityCategories',
         key: 'entityCategories',
         width: 300,
         render: (_, record) => (
            <Space size="small" wrap>
               {getCategoryNames(_).map((item) => (
                  <React.Fragment key={`category-${record.id}-${item}`}>
                     {item && <Tag color={stringToColor(item)}>{item}</Tag>}
                  </React.Fragment>
               ))}
            </Space>
         )
      },
      {
         title: 'Статус',
         dataIndex: 'status',
         key: 'status',
         render: (_) => (
            <Tag color={statusesOfAcceptances[_]?.color}>
               {statusesOfAcceptances[_]?.label}
            </Tag>
         ),
         filtered: !!filters?.['status'],
         ...getStatusColumnFilterProps({
            options: Object.values(statusesOfAcceptances),
            dataIndex: 'status',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         })
      },
      {
         title: 'Водитель',
         dataIndex: 'driverId',
         key: 'driverId',
         render: (_, record) => (
            <Space size="small" wrap>
               <div className="address_list">
                  {record?.driver ? (
                     <List
                        itemLayout="horizontal"
                        dataSource={[record.driver]}
                        renderItem={(item) => (
                           <List.Item key={item.toString()}>
                              <List.Item.Meta
                                 title={
                                    <Space>
                                       <span>
                                          {item?.firstName
                                             ? `${item?.firstName} ${
                                                  item?.lastName || ''
                                               }`
                                             : 'ФИО отсутствует'}
                                       </span>
                                       <Tooltip
                                          placement="topRight"
                                          title={
                                             statusesOfDrivers[item?.status]?.label
                                          }
                                          arrow={false}>
                                          <Badge
                                             status={
                                                statusesOfDrivers[item?.status]
                                                   ?.badgeStatus
                                             }
                                             className="driver-status-badge"
                                          />
                                       </Tooltip>
                                    </Space>
                                 }
                                 description={item?.mobileNumber}
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                 }}
                              />
                           </List.Item>
                        )}
                     />
                  ) : (
                     `Без водителя`
                  )}
               </div>
            </Space>
         )
      },
      {
         title: 'Действие',
         key: 'action',
         width: 200,
         render: (_, record) => (
            <Space size="middle">
               {/* <EnterToEntityButton id={record.id} entity="acceptances" /> */}

               <Tooltip placement="top" title={'Редактировать'}>
                  <Button
                     onClick={() => setSelectedAcceptance(record)}
                     type="primary">
                     <EditOutlined />
                  </Button>
               </Tooltip>

               {['blocked', 'pending'].includes(record.status) && (
                  <DeleteEntityButton
                     id={record.id}
                     callbackFetch={deleteAcceptanceById}
                     update={fetchData}
                     permission="can_delete_acceptances"
                  />
               )}
            </Space>
         )
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   const closeModal = () => {
      setSelectedAcceptance(null);
      fetchData();
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);
      GetAcceptanceList({
         page: params.pagination.current,
         limit: params.pagination.pageSize,
         ...params.sorter,
         ...params.filters
      }).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, total: res.count }
         });

         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   return (
      <>
         <ModalAcceptanceForm
            selectedAcceptance={selectedAcceptance}
            closeModal={closeModal}
         />
         <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <ModalButtonAcceptanceCreate closeModal={closeModal} />
         </Space>
         <VerticalSpace />

         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space size="small">
                  <TotalCountTableText total={pagination.total} />
               </Space>
            </div>
         </div>

         <Table
            columns={[...columns, ...actions]}
            dataSource={data}
            pagination={false}
            rowKey="id"
            onChange={handleTableChange}
            loading={isLoading}
         />
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableAcceptances;
