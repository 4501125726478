import React, { useState, useEffect } from 'react';
import {
   Space,
   Button,
   Tag,
   Divider,
   Steps,
   List,
   Spin,
   Tooltip,
   Modal,
   Switch,
   Alert,
   Radio,
   Input
} from 'antd';
import {
   DownloadOutlined,
   DeleteOutlined,
   EditOutlined,
   ExclamationCircleFilled,
   QuestionCircleOutlined,
   AlignCenterOutlined,
   LoadingOutlined,
   CheckOutlined,
   CloseOutlined
} from '@ant-design/icons';
import ModalImage from 'react-modal-image';
const { confirm } = Modal;
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import { message } from 'antd';
import { getMessageText } from '@shared/utils/entity';
import VerificationIcon from '@features/verification/ui/VerificationIcon';
import { UpdateDriver } from '@features/form-driver/model/services/UpdateDriver';
import { createCustomPushNotification } from '@shared/api/all/pushNotification';
import {
   getVerificationsOfEntity,
   updateVerificationOfEntityById
} from '@shared/api/all/verification';

import { VerticalSpace } from '@shared/ui';

const verificationTypes = {
   profilePhoto: 'Фото лица',
   photoCarNumber: 'Фото транспортного средства с гос. номером',
   photoDriverLicense: 'Фото водительских прав'
};

const getImageSrc = (message) => {
   if (message.includes('data:image')) {
      return message;
   }

   return `data:image/jpeg;charset=utf-8;base64, ${message}`;
};

const EntityVerificationComponent = ({
   entityId,
   entityType,
   verified,
   onChangeEntity,
   mobileNumber
}) => {
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);

   const [selectedId, setSelectedId] = useState(null);
   const [cause, setCause] = useState('');

   const isModalOpen = selectedId !== null;

   const fetchData = (entityType, entityId) => {
      setLoading(true);
      if (!entityId || !entityType) {
         return;
      }
      getVerificationsOfEntity(entityType, entityId)
         .then((res) => {
            setLoading(false);

            const sortedByIdArr = res.json.data.sort((a, b) => b.id - a.id);
            setData(sortedByIdArr.reverse());
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   };

   useEffect(() => {
      if (entityId && entityType) {
         fetchData(entityType, entityId);
      }
   }, [entityId, entityType]);

   const onChangeVerification = (e) => {
      if (!entityId || !entityType) {
         return;
      }

      UpdateDriver(
         {
            verified: e
         },
         entityId
      )
         .then((res) => {
            setLoading(false);
            onChangeEntity();
            message.success(e ? 'Верификация пройдена' : 'Верификация отменена');

            const notificationObj = {
               adresat: 'one',
               entityId: entityId,
               entityType: entityType,
               message: e
                  ? 'Поздравляем, вам доступны все функции в приложении'
                  : 'При верификации возникли проблемы, зайдите в приложение чтобы ознакомиться подробнее',
               title: e ? 'Верификация пройдена' : 'Верификация не пройдена'
            };

            createCustomPushNotification(notificationObj).then(() => {
               message.success('Пуш уведомление отправлено клиенту');
            });
         })

         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   };

   const showConfirm = (id) => {
      return confirm({
         title: 'Подтвердите действие',
         icon: <ExclamationCircleFilled />,
         maskClosable: true,
         onOk() {
            updateVerificationOfEntityById(id, {
               imageHash: null
            }).then((res) => {
               fetchData(entityType, entityId);
               message.success('Фото удалено');
            });
         },
         okText: 'Удалить',
         cancelText: 'Отмена'
      });
   };

   const onChangeStatusVerificationType = (id, status) => {
      if (!entityId || !entityType) {
         return;
      }
      setLoading(true);

      updateVerificationOfEntityById(id, {
         status: status,
         comment: ''
      })
         .then((res) => {
            setLoading(false);
            message.success('Статус верификации изменен');
            fetchData(entityType, entityId);

            onChangeEntity();

            if (status === 'error') {
               const notificationObj = {
                  adresat: 'one',
                  entityId: entityId,
                  entityType: entityType,
                  message:
                     'При верификации возникли проблемы, зайдите в приложение чтобы ознакомиться подробнее',
                  title: 'Верификация не пройдена'
               };
               showModal(id);

               createCustomPushNotification(notificationObj).then(() => {
                  message.success('Пуш уведомление об ошибке отправлено клиенту');
               });
            }
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const showModal = (id) => {
      setSelectedId(id);
   };

   const handleOk = () => {
      updateVerificationOfEntityById(selectedId, {
         comment: cause
      }).then((res) => {
         setCause('');
         fetchData(entityType, entityId);
         message.success('Комментарий добавлен');
         setSelectedId(null);
      });
   };
   const handleCancel = () => {
      setSelectedId(null);
   };

   if (loading) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '300px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   return (
      <Space direction="vertical" style={{ width: '100%' }} className="note">
         <Modal
            title="Укажите причину отказа"
            open={isModalOpen}
            onOk={handleOk}
            okText="Отправить"
            cancelText="Отмена"
            centered={true}
            onCancel={handleCancel}>
            <Input
               type="text"
               size="large"
               placeholder="Введите причину отказа"
               style={{ width: '100%' }}
               onChange={(e) => setCause(e.target.value)}
               value={cause}
            />
         </Modal>

         <div
            style={{
               display: 'flex',
               justifyContent: 'flex-start',
               gap: '10px',
               alignItems: 'center',
               marginBottom: '15px'
            }}>
            <Switch
               loading={loading}
               value={verified}
               defaultChecked={verified}
               onChange={(e) => onChangeVerification(e)}
            />
            {verified ? 'Верифицирован' : 'Не верифицирован'}
            <VerificationIcon verified={verified} />
         </div>

         <div>
            {data.length > 0 ? (
               <div>
                  {data.map((item) => {
                     return (
                        <Alert
                           key={item.id}
                           style={{ marginBottom: '10px' }}
                           icon={
                              item.status === 'pending' ? (
                                 <LoadingOutlined />
                              ) : item.status === 'success' ? (
                                 <CheckOutlined />
                              ) : (
                                 <CloseOutlined />
                              )
                           }
                           message={
                              <span>
                                 {verificationTypes[item.type]} <br />
                                 <Radio.Group
                                    size="small"
                                    style={{ marginTop: '5px' }}
                                    defaultValue="pending"
                                    buttonStyle="solid"
                                    loading={true}
                                    onChange={(e) =>
                                       onChangeStatusVerificationType(
                                          item.id,
                                          e.target.value
                                       )
                                    }
                                    value={item.status}>
                                    <Radio.Button value="success">
                                       Подтвержден
                                    </Radio.Button>
                                    <Radio.Button value="error">
                                       Не подтверждено
                                    </Radio.Button>
                                    <Radio.Button value="pending">
                                       Не выбрано
                                    </Radio.Button>
                                 </Radio.Group>
                                 {item.comment && (
                                    <div style={{ marginTop: '5px' }}>
                                       Причина отказа: {item.comment}
                                    </div>
                                 )}
                                 <div style={{ width: '100%', paddingTop: '5px' }}>
                                    {item.imageHash ? (
                                       <>
                                          <div
                                             style={{
                                                maxWidth: '90px',
                                                maxHeight: '100%'
                                             }}>
                                             <ModalImage
                                                small={getImageSrc(item.imageHash)}
                                                large={getImageSrc(item.imageHash)}
                                                alt="Картинка"
                                             />
                                          </div>
                                          <Button
                                             style={{ marignTop: '10px' }}
                                             onClick={() => showConfirm(item.id)}
                                             danger
                                             type="primary">
                                             Удалить фото
                                          </Button>
                                       </>
                                    ) : (
                                       <>Фото не загружено</>
                                    )}{' '}
                                 </div>
                              </span>
                           }
                           type={
                              item.status === 'pending'
                                 ? 'info'
                                 : item.status === 'success'
                                 ? 'success'
                                 : 'error'
                           }
                           showIcon></Alert>
                     );
                  })}
               </div>
            ) : (
               <div>
                  <div>Фото лица - не загружено</div>
                  <div>Фото транспортного средства - не загружено</div>
                  <div>Фото прав - не загружено</div>
               </div>
            )}
         </div>
      </Space>
   );
};

export default EntityVerificationComponent;
